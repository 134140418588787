






























import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Unit } from "@/entities";

const Store = namespace("Application");

@Component({
  name: "project-listing"
})
export default class ProjectListing extends Vue {
  public unitHeight = 400;

  @Store.Getter
  public unit!: Unit;

  @Prop()
  public id!: string;

  public viewPresentation(id: string): void {
    this.$router.push({
      name: "ViewPresentation",
      params: {
        region: this.unit.region,
        year: this.unit.year.toString(),
        unitId: this.unit.id,
        presentationId: id
      }
    });
  }

  resize(): void {
    this.unitHeight = window.innerHeight - 130;
  }

  mounted(): void {
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  beforeDestroy(): void {
    window.removeEventListener("resize", this.resize);
  }
}
