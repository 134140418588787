






import ProjectListing from "@/components/ProjectListing.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  Application,
  GetUnitActionParams
} from "@/state/Application/application";
import { getModule } from "vuex-module-decorators";

const Store: Application = getModule(Application);

@Component({
  components: {
    ProjectListing
  }
})
export default class ViewUnit extends Vue {
  @Prop({ required: true })
  public region!: string;

  @Prop({ required: true })
  public year!: number;

  @Prop({ required: true })
  public unitId!: string;

  mounted() {
    if (Store.unit === null || Store.unit.id !== this.unitId) {
      Store.getUnit(
        new GetUnitActionParams(this.region, this.year, this.unitId)
      );
    }
  }
}
